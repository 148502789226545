/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@use "../../../../../tokens";

.container {
  padding: tokens.$layout-xs;

  .wrapper {
    padding: tokens.$layout-xs;
    border: 2px solid tokens.$color-grey-20;
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    gap: tokens.$layout-lg;

    .notificationsWrapper {
      ul {
        padding-top: tokens.$spacing-lg;
        list-style-type: none;
        margin: 0;
        padding-left: 0;

        li {
          padding: tokens.$spacing-md;
          border: 1px solid tokens.$color-grey-40;
          border-radius: tokens.$border-radius-sm;
          margin-bottom: tokens.$spacing-sm;
          cursor: pointer;
          justify-content: flex-start;
          display: flex;
          align-items: center;

          &.active {
            border: 2px solid tokens.$color-purple-70;
          }

          .title {
            font-weight: 700;
          }

          .pills {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            gap: tokens.$spacing-xs;
            margin-left: auto;

            .statusPill {
              border-radius: tokens.$border-radius-lg;
              padding: tokens.$spacing-xs tokens.$spacing-sm;
              text-align: center;
              min-width: 100px;
              align-items: center;
              align-self: center;
              text-transform: capitalize;
              font-size: 14px;
              font-weight: 700;

              &.draft {
                background-color: tokens.$color-grey-20;
                color: tokens.$color-grey-50;
              }

              &.published {
                background-color: tokens.$color-green-30;
                color: tokens.$color-green-90;
              }
            }
          }
        }
      }
    }

    .notificationSettings {
      dl {
        dt {
          font-weight: 600;
          color: tokens.$color-grey-50;
        }

        dd {
          margin-bottom: tokens.$spacing-md;

          .smallImage {
            width: 70px;
            height: auto;
          }

          .bigImage {
            width: 300px;
            height: auto;
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        gap: tokens.$spacing-sm;
        button {
          all: unset;
          background-color: tokens.$color-grey-10;
          font-weight: 600;
          padding: tokens.$spacing-sm tokens.$spacing-md;
          border-radius: tokens.$border-radius-md;
          cursor: pointer;

          &.deleteBtn {
            background-color: tokens.$color-red-10;
          }
        }
      }
    }

    .previewModalWrapper {
      .previewModal {
        width: 100%;
        padding: tokens.$spacing-md;
        border-radius: tokens.$border-radius-sm;
        border: 1px solid tokens.$color-grey-20;
        display: flex;
        flex-direction: column;

        img {
          object-fit: contain;
          width: 100%;
          height: auto; /* Maintain aspect ratio */
        }

        dt {
          font-size: 18px;
          font-weight: 700;
          padding: tokens.$spacing-sm 0;
        }

        a {
          padding-top: tokens.$spacing-md;
        }
      }
    }
  }

  .missingLabelContainer {
    display: flex;
    flex-direction: row;
    gap: tokens.$spacing-sm;
    align-items: center;

    .missingLabel {
      font-size: 12px;
      font-weight: 600;
      color: tokens.$color-yellow-70;
      background-color: tokens.$color-yellow-20;
      border-radius: tokens.$border-radius-lg;
      padding: tokens.$spacing-xs tokens.$spacing-sm;
    }
  }

  .imagePathUnavailable {
    color: tokens.$color-red-50;
    font-weight: 600;
  }
}

.addButton {
  all: unset;
  background-color: tokens.$color-grey-10;
  font-weight: 600;
  padding: tokens.$spacing-sm tokens.$spacing-md;
  border-radius: tokens.$border-radius-md;
  cursor: pointer;
  margin-top: tokens.$spacing-md;
}
